import axios from 'axios'
import { authString } from '../context/services/AuthHeader'
import toast from '../components/molecules/RwToast'

export const API_GET = 'get'
export const API_POST = 'post'
export const API_PUT = 'put'
export const API_PATCH = 'patch'
export const API_DELETE = 'delete'

export interface ApiOptions {
  throwError?: boolean
  navigate?: () => void // Add navigate function in ApiOptions
}

export interface IApi {
  method?: 'get' | 'delete' | 'post' | 'put' | 'patch'
  url: string
  data?: any
  options?: ApiOptions
}

class Api {
  static defaultNavigateToLogin = () => {
    if (window.location.pathname !== '/login') {
      const confirmRedirect = window.confirm(
        'Your session has expired. Click OK to return to the login screen.'
      );

      window.location.href = '/login'; // Navigate only if user confirms
      
    }
  };

  static async call(props: IApi) {
    const { method, url, data, options } = props;
    axios.defaults.headers.common['Authorization'] = authString();

    const response: any = await axios({ method, url, data }).catch((error) => {
      if (options?.throwError) {
        throw error;
      } else {
        if (error.response) {
          if (error.response.status === 401) {
            (options?.navigate || Api.defaultNavigateToLogin)();
          } else {
            toast.error(error.response.data.message);
          }
        } else if (error.request) {
          console.log('error.request', error.request);
        } else {
          console.log('Error', error.message);
          toast.error(error.message);
        }
      }
    });

    return response;
  }

  static async get(url: string, options?: ApiOptions) {
    return Api.call({ method: API_GET, url, options })
  }

  static async post(url: string, data: any, options?: ApiOptions) {
    return Api.call({ method: API_POST, url, data, options })
  }

  static async put(url: string, data: any, options?: ApiOptions) {
    return Api.call({ method: API_PUT, url, data, options })
  }

  static async patch(url: string, data: any, options?: ApiOptions) {
    return Api.call({ method: API_PATCH, url, data, options })
  }

  static async delete(url: string, options?: ApiOptions) {
    return Api.call({ method: API_DELETE, url, options })
  }
}

export { Api as api }