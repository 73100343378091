import React, { JSXElementConstructor, ReactElement, ReactNode } from 'react'
import { Nav, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap'
import { useUserContext } from '../../../context/user/UserProvider'
import {
  BsFillPlusSquareFill,
  BsFillXSquareFill,
  BsFillTrashFill,
  BsFillFloppyFill,
  BsFillBanFill,
  BsCopy,
  BsExclamationCircle,
  BsCheckSquareFill,
  BsUpload,
  BsCheckSquare,
  BsSquare,
  BsPencilSquare,
  BsFillQuestionCircleFill,
  BsChatRightText,
  BsPinAngle,
  BsCalendar2Check,
  BsFillPinFill,
  BsFillPersonFill,
  BsDownload,
} from 'react-icons/bs'
import {
  FaArrowRotateRight,
  FaArrowRotateLeft,
  FaFileCsv,
  FaFileExcel,
  FaKey,
  FaLock,
  FaLockOpen,
  FaFileInvoice,
  FaDollarSign,
  FaEnvelopeOpen,
} from 'react-icons/fa6'
import { MdLocalShipping } from 'react-icons/md'

import { RiFilterOffLine, RiFilterLine } from 'react-icons/ri'
import { MdOutlineHistory, MdRequestQuote } from 'react-icons/md'
import { isAuthorized } from '../../../helpers/Authorization'
import { useQueryClient } from '@tanstack/react-query'
import { userKeys } from '../../../queries/user/keyFactory'
import { IUserData } from '../../../queries/user/types'
import { Placement } from 'react-bootstrap/esm/types'
import RwSubmitButton from '../RwButton/RwSubmitButton'
export interface IRwNavItem {
  title?: ReactNode
  tooltip?: ReactNode
  tooltipPlacement?: Placement | undefined
  variant?:
    | 'add'
    | 'edit'
    | 'remove'
    | 'refresh'
    | 'previous'
    | 'deactivate'
    | 'save'
    | 'close'
    | 'revert'
    | 'clone'
    | 'reset-filters'
    | 'csv'
    | 'form-errors'
    | 'complete'
    | 'excel'
    | 'upload-file'
    | 'checked'
    | 'unchecked'
    | 'key'
    | 'help'
    | 'audit'
    | 'comments'
    | 'pin'
    | 'pinned'
    | 'unpinned'
    | 'lock'
    | 'lock-open'
    | 'request-updates'
    | 'calendar-day'
    | 'invoice'
    | 'create-shipment'
    | 'payment'
    | 'combine'
    | 'quote'
    | 'account'
    | 'mail'
    | 'filter'
    | 'download'

  icon?: ReactElement<any, string | JSXElementConstructor<any>>
  disabled?: boolean
  className?: string
  fill?: boolean
  permission?: string
  isLoading?: boolean
  isSubmit?: boolean
  [x: string]: any
}

const RwNavItem: React.FC<IRwNavItem> = ({
  title = null,
  tooltip = null,
  tooltipPlacement = 'right',
  variant = null,
  icon = null,
  disabled = false,
  className = '',
  fill = false,
  permission = null,
  isLoading = false,
  isSubmit = false,
  ...rest
}) => {
  const { isDarkMode } = useUserContext()

  const queryClient = useQueryClient()
  const user = queryClient.getQueryData(userKeys.user) as IUserData

  const iconClass = 'my-auto me-2 fs-4'
  let variantIcon = null
  let variantTitle = null
  let variantClass = ''
  let titleColor = ''

  switch (variant) {
    case 'add':
      variantIcon = <BsFillPlusSquareFill />
      variantTitle = 'New'
      break
    case 'edit':
      variantIcon = <BsPencilSquare />
      variantTitle = 'Edit'
      break
    case 'request-updates':
      variantIcon = <FaLock />
      variantTitle = 'Request Updates'
      break
    case 'lock':
      titleColor = 'green'
      variantIcon = <FaLock style={{ color: titleColor }} />
      variantTitle = 'Lock'
      break
    case 'lock-open':
      titleColor = '#c62828'
      variantIcon = <FaLockOpen style={{ color: titleColor }} />
      variantTitle = 'LockOpen'
      break
    case 'remove':
      variantIcon = <BsFillTrashFill />
      variantTitle = 'Delete'
      break
    case 'refresh':
      variantIcon = <FaArrowRotateRight />
      variantTitle = 'Refresh'
      break
    case 'previous':
      variantIcon = <FaArrowRotateRight />
      variantTitle = 'Previous'
      break
    case 'deactivate':
      variantIcon = <BsFillBanFill />
      variantTitle = 'Deactivate'
      break
    case 'save':
      variantIcon = <BsFillFloppyFill />
      variantTitle = 'Save'
      break
    case 'close':
      variantIcon = <BsFillXSquareFill />
      variantTitle = 'Close'
      break
    case 'revert':
      variantIcon = <FaArrowRotateLeft />
      variantTitle = 'Revert'
      break
    case 'clone':
      variantIcon = <BsCopy />
      variantTitle = 'Clone'
      break
    case 'reset-filters':
      variantIcon = <RiFilterOffLine />
      variantTitle = 'Reset Filters'
      break
    case 'csv':
      variantIcon = <FaFileCsv />
      variantTitle = 'Export CSV'
      break
    case 'form-errors':
      variantIcon = <BsExclamationCircle />
      variantTitle = 'Form Errors'
      variantClass = 'text-danger'
      break
    case 'complete':
      variantIcon = <BsCheckSquareFill />
      variantTitle = 'Complete'
      break
    case 'excel':
      variantIcon = <FaFileExcel />
      variantTitle = title ? title : 'Export Excel'
      break
    case 'upload-file':
      variantIcon = <BsUpload />
      variantTitle = 'Upload File'
      break
    case 'checked':
      variantIcon = <BsCheckSquare />
      variantTitle = 'Selected'
      break
    case 'unchecked':
      variantIcon = <BsSquare />
      variantTitle = 'Not Selected'
      break
    case 'key':
      variantIcon = <FaKey />
      variantTitle = 'Key'
      break
    case 'help':
      variantIcon = <BsFillQuestionCircleFill />
      variantTitle = 'Help'
      break
    case 'audit':
      variantIcon = <MdOutlineHistory />
      variantTitle = 'Audit'
      break
    case 'comments':
      variantIcon = <BsChatRightText />
      variantTitle = 'Comments'
      break
    case 'pin':
      variantIcon = <BsPinAngle />
      variantTitle = 'Pin'
      break
    case 'pinned':
      variantIcon = <BsFillPinFill />
      variantTitle = ''
      break
    case 'unpinned':
      variantIcon = <BsPinAngle />
      variantTitle = ''
      break
    case 'calendar-day':
      variantIcon = <BsCalendar2Check />
      variantTitle = 'Today'
      break
    case 'invoice':
      variantIcon = <FaFileInvoice />
      variantTitle = 'Invoice'
      break
    case 'create-shipment':
      variantIcon = <MdLocalShipping />
      variantTitle = 'Create Shipment'
      break
    case 'payment':
      variantIcon = <FaDollarSign />
      variantTitle = 'Payment'
      break
    case 'combine':
      variantIcon = <BsFillPlusSquareFill />
      variantTitle = 'Combine'
      break
    case 'quote':
      variantIcon = <MdRequestQuote />
      variantTitle = 'Quote'
      break
    case 'account':
      variantIcon = <BsFillPersonFill />
      variantTitle = 'Account'
      break
    case 'mail':
      variantIcon = <FaEnvelopeOpen />
      variantTitle = 'Mail'
      break
    case 'filter':
      variantIcon = <RiFilterLine />
      variantTitle = 'Filter'
      break
    case 'download':
      variantIcon = <BsDownload />
      variantTitle = 'Download'
      break
  }

  if (isLoading) {
    variantIcon = <Spinner size="sm" />
  }

  const iconClone = icon ? (
    React.cloneElement(icon, {
      className: iconClass,
    })
  ) : variantIcon ? (
    React.cloneElement(variantIcon, {
      className: iconClass,
    })
  ) : (
    <></>
  )
  const hasAccess = !permission || isAuthorized(user, [permission])
  const isDisabled = disabled || !hasAccess

  const opacity = isDisabled ? 0.5 : 1.0

  const hoverClass = isDisabled
    ? ''
    : isDarkMode
    ? 'rw-nav-item-dm'
    : 'rw-nav-item'

  const alignClass = fill ? 'ms-auto' : ''
  const onClickOverride = isDisabled || isLoading ? { onClick: () => {} } : {}
  const minWidth = isLoading ? '28px' : ''
  const navItem = (
    <Nav
      className={`rounded ${hoverClass} ${alignClass} ${variantClass} px-2 py-2 ${
        !isDisabled && 'cursor-pointer'
      } ${isDarkMode ? 'text-light' : 'text-primary'} ${className}`}
      style={{ opacity: opacity }}
      {...rest}
      {...onClickOverride}
    >
      <div className="d-flex">
        <span style={{ minWidth: minWidth }}>{iconClone}</span>
        <span className="fs-5 " style={{ color: titleColor }}>
          {title ? title : variantTitle ? variantTitle : null}
        </span>
        {isSubmit && !isDisabled && !isLoading ? (
          <RwSubmitButton className="d-none" />
        ) : (
          <></>
        )}
      </div>
    </Nav>
  )

  return (
    <>
      {tooltip ? (
        <OverlayTrigger
          delay={500}
          placement={tooltipPlacement}
          overlay={<Tooltip>{tooltip}</Tooltip>}
        >
          {navItem}
        </OverlayTrigger>
      ) : (
        <>{navItem}</>
      )}
    </>
  )
}

export default RwNavItem
