import {
  GET_CUSTOMER_SHIPPING_ADDRESSES,
  SET_CC_PATH,
  CREATE_SHIPMENT_ORDER,
  GET_QUOTES_FORM_DATA,
  GET_SHIPPING_QUOTES,
  UPDATE_QUOTE,
  SET_QUOTES_LOADING,
  SET_SELECTED_QUOTE,
  ORDER_SHIPPING_QUOTE,
  SHIPPING_ORDER_DETAILS,
} from './constants'

import { IShippingAction, IShippingQuote, IShippingState } from './types'

export default function shippingReducer(
  state: IShippingState,
  action: IShippingAction,
): IShippingState {
  const payload = action.payload
  const { shippingQuotes } = state
  switch (action.type) {
    case CREATE_SHIPMENT_ORDER:
      return {
        ...state,
        createShipmentOrder: payload,
      }
    case GET_CUSTOMER_SHIPPING_ADDRESSES:
      return {
        ...state,
        shippingAddresses: payload,
      }
    case SET_CC_PATH:
      return {
        ...state,
        ccPath: payload,
      }
    case GET_QUOTES_FORM_DATA:
      return {
        ...state,
        quotesModalData: payload,
      }
    case GET_SHIPPING_QUOTES:
      return {
        ...state,
        shippingQuotes: payload,
      }
    case UPDATE_QUOTE:
      return {
        ...state,
        shippingQuotes: (state.shippingQuotes || []).map(
          (quote: IShippingQuote) =>
            quote.carrier_option_id === action.payload.carrier_option_id
              ? action.payload
              : quote,
        ),
      }
    case SET_QUOTES_LOADING:
      return {
        ...state,
        isLoadingQuotes: payload,
      }
    case SET_SELECTED_QUOTE:
      return {
        ...state,
        selectedQuote: payload,
      }
    case ORDER_SHIPPING_QUOTE:
      return {
        ...state,
        orderShippingQuote: payload,
      }
    case SHIPPING_ORDER_DETAILS:
      return {
        ...state,
        shippingOrderDetails: payload,
      }
    default:
      return state
  }
}
