import { createContext, useReducer, useContext, useEffect } from 'react'
import {
  GET_NOTES,
  CLEANUP_NOTES,
  SET_NOTE_KEY_VALUE,
  SET_NOTE_TYPE_ID,
  SET_GET_NOTE_TYPES,
  CLEANUP_NOTE_TYPE_ID,
  CLEANUP_NOTE_KEY_VALUE,
  CLEANUP_GET_NOTE_TYPES,
} from './constants'
import notesReducer from './NotesReducer'
import { INotesState, INote } from './types'
import toast from '../../components/molecules/RwToast'
import { api } from '../../helpers/Api'
import { API_NOTES_URL } from '../apiconfig'

const DEV_MESSED_UP_ERROR =
  'A keyValue has not been set or has not been set correctly. Please try again in a moment and if the problem persists, contact the IT department.'

export const initialState: INotesState = {
  notes: [],
  noteTypeID: null,
  getNoteTypes: null,
  keyValue: '',
  setNoteType: () => {},
  setNoteKeyValue: () => {},
  setGetNoteTypes: () => {},
  getNotes: () => {},
  createNote: () => {},
  updateNote: () => {},
  cleanupNotesContext: () => {},
  cleanupNotes: () => {},
  cleanupNotesKeys: () => {},
}

export const NotesContext = createContext(initialState)

export const useNotesContext = () => useContext(NotesContext)

export interface IPricingProviderProps {
  children?: React.ReactNode
}

export const NotesProvider = (props: IPricingProviderProps): JSX.Element => {
  const { children } = props

  const [state, dispatch] = useReducer(notesReducer, initialState)

  const cleanupNotesContext = () => {
    cleanupNotes()
    cleanupNotesKeys()
  }

  const cleanupNotes = () => {
    dispatch({ type: CLEANUP_NOTES })
  }

  const cleanupNotesKeys = () => {
    dispatch({ type: CLEANUP_NOTE_TYPE_ID })
    dispatch({ type: CLEANUP_NOTE_KEY_VALUE })
    dispatch({ type: CLEANUP_GET_NOTE_TYPES })
  }

  const setNoteType = (note_type_id: number) => {
    dispatch({ type: SET_NOTE_TYPE_ID, payload: note_type_id })
  }

  const setNoteKeyValue = (key_value: string | number) => {
    dispatch({ type: SET_NOTE_KEY_VALUE, payload: key_value })
  }

  const setGetNoteTypes = (types_arr: number[]) => {
    dispatch({ type: SET_GET_NOTE_TYPES, payload: types_arr })
  }

  useEffect(() => {
    if (state.keyValue && (state.noteTypeID || state.getNoteTypes)) {
      getNotes()
    }
  }, [state.getNoteTypes, state.noteTypeID, state.keyValue])

  const getNotes = async () => {
    if (!(state.noteTypeID || state.getNoteTypes) || !state.keyValue) {
      toast.error(DEV_MESSED_UP_ERROR)
      return
    } else {
      const response = await api.post(`${API_NOTES_URL}read`, {
        note_types_ids: state.getNoteTypes || [state.noteTypeID],
        key_value: state.keyValue,
      })

      if (response.data.success) {
        dispatch({ type: GET_NOTES, payload: response.data.data })
      } else {
        toast.error(response.data.message || 'Something went wrong.')
      }
    }
  }

  const createNote = async (
    note_content: string,
    override_note_type?: number,
  ) => {
    if (!(state.noteTypeID || override_note_type) || !state.keyValue) {
      toast.error(DEV_MESSED_UP_ERROR)
      return
    } else {
      const response = await api.post(`${API_NOTES_URL}create`, {
        note_types_id: override_note_type || state.noteTypeID,
        key_value: state.keyValue,
        note: note_content,
        return_note_types: state.getNoteTypes || [state.noteTypeID],
      })
      if (response.data.success) {
        toast.success(response.data.message || 'Note created.')
        dispatch({ type: GET_NOTES, payload: response.data.data })
      } else {
        toast.error(response.data.message || 'Something went wrong.')
      }
    }
  }

  const updateNote = async (note: INote) => {
    const response = await api.post(`${API_NOTES_URL}update`, {
      note,
      return_note_types: state.getNoteTypes || [state.noteTypeID],
    })
    if (response.data.success) {
      toast.success(response.data.message)
      dispatch({ type: GET_NOTES, payload: response.data.data })
    } else {
      toast.error(response.data.message || 'Something went wrong.')
    }
  }

  const value = {
    ...state,
    setNoteType,
    setNoteKeyValue,
    setGetNoteTypes,
    getNotes,
    createNote,
    updateNote,
    cleanupNotesContext,
    cleanupNotes,
    cleanupNotesKeys,
  }

  return <NotesContext.Provider value={value}>{children}</NotesContext.Provider>
}
