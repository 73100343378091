import { uniqueId } from 'lodash'
import { ISelectOption } from '../../types/interfaces'

export const CREATE_SHIPMENT_ORDER = 'CREATE_SHIPMENT_ORDER'
export const GET_CUSTOMER_SHIPPING_ADDRESSES = 'GET_CUSTOMER_SHIPPING_ADDRESSES'
export const SET_CC_PATH = 'SET_CC_PATH'
export const GET_SHIPPING_QUOTES = 'GET_SHIPPING_QUOTES'
export const GET_QUOTES_FORM_DATA = 'GET_QUOTES_FORM_DATA'
export const UPDATE_QUOTE = 'UPDATE_QUOTE'
export const SET_QUOTES_LOADING = 'SET_QUOTES_LOADING'
export const SET_SELECTED_QUOTE = 'SET_SELECTED_QUOTE'
export const ORDER_SHIPPING_QUOTE = 'ORDER_SHIPPING_QUOTE'
export const SHIPPING_ORDER_DETAILS = 'SHIPPING_ORDER_DETAILS'

export const stockStatusFilterOptions: Array<ISelectOption> = [
  { value: 'green', label: 'Green', id: uniqueId() },
  { value: 'yellow', label: 'Yellow', id: uniqueId() },
  { value: 'yellow_hold', label: 'Yellow Hold', id: uniqueId() },
  { value: 'red', label: 'Red', id: uniqueId() },
  { value: 'shipped', label: 'Shipped', id: uniqueId() },
  { value: 'void', label: 'Void', id: uniqueId() },
]

export const sortByOptions: Array<ISelectOption> = [
  { value: 'delivery_date', label: 'Delivery Date', id: 'delivery_date' },
  { value: 'pallet_count', label: 'Pallet Count', id: 'pallet_count' },
  { value: 'delivery_state', label: 'Delivery State', id: 'delivery_state' },
]

export const shippingFieldMap = {
  delivery_country: 'Shipping Country',
  delivery_city: 'Shipping City',
  customers_telephone: 'Shipping Phone',
  delivery_name: 'Shipping Name',
  delivery_company: 'Shipping Company',
  delivery_street_address: 'Shipping Street Address',
  delivery_postcode: 'Shipping ZIP Code',
  delivery_state: 'Shipping State',
  delivery_suburb: 'Shipping Apt/Suite',
  orders_status: 'Order Status',
  rush: 'Rush Shipping',
  DestinationHasLoadingDock: 'Has Loading Dock',
  DestinationRequiresLiftgate: 'Needs Liftgate',
  DestinationIsResidential: 'Residential Destination',
  DestinationArrivalNotification: 'Arrival Notification',
  DestinationHasPallet_Jack: 'Has Pallet Jack',
  DestinationHasForklift: 'Has Forklift',
}
