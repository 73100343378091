import {
  SET_ORDERS_LIST_FILTERS,
  CLEAR_FILTER_CONTEXT,
  defaultOrdersListFilters,
  CLEAR_ORDERS_LIST_FILTERS,
  CLEAR_SHIPPING_ORDERS_LIST_FILTERS,
  SET_SHIPPING_ORDERS_LIST_FILTERS,
  defaultShippingOrdersListFilters,
} from './constants'
import { initialState } from './FilterProvider'
import { IFilterAction, IFilterState } from './types'

export default function filterReducer(
  state: IFilterState = initialState,
  action: IFilterAction,
): IFilterState {
  const payload = action.payload

  switch (action.type) {
    case SET_ORDERS_LIST_FILTERS:
      return {
        ...state,
        ordersListFilters: payload || defaultOrdersListFilters,
      }
    case SET_SHIPPING_ORDERS_LIST_FILTERS:
      return {
        ...state,
        shippingOrdersListFilters: payload || defaultOrdersListFilters,
      }
    case CLEAR_ORDERS_LIST_FILTERS:
      return {
        ...state,
        ordersListFilters: defaultOrdersListFilters,
      }
    case CLEAR_SHIPPING_ORDERS_LIST_FILTERS:
      return {
        ...state,
        shippingOrdersListFilters: defaultShippingOrdersListFilters,
      }
    case CLEAR_FILTER_CONTEXT:
      return initialState
    default:
      return state
  }
}
