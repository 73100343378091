import React from 'react'
import { createContext, useReducer, useContext } from 'react'
import {
  IShippingState,
  IShipAddress,
  IQuotesModalData,
  IShippingQuote,
} from './types'
import {
  CREATE_SHIPMENT_ORDER,
  GET_CUSTOMER_SHIPPING_ADDRESSES,
  SET_CC_PATH,
  GET_SHIPPING_QUOTES,
  GET_QUOTES_FORM_DATA,
  UPDATE_QUOTE,
  SET_QUOTES_LOADING,
  SET_SELECTED_QUOTE,
  ORDER_SHIPPING_QUOTE,
  SHIPPING_ORDER_DETAILS,
} from './constants'
import shippingReducer from './ShippingReducer'
import { api } from '../../helpers/Api'
import { API_SHIPPING_URL } from '../apiconfig'
import axios, { AxiosRequestConfig } from 'axios'
import toast from '../../components/molecules/RwToast'
import authHeader from '../services/AuthHeader'

export const initialState: IShippingState = {
  createShipmentOrder: null,
  shippingAddresses: [],
  ccPath: '',
  shippingQuotes: null,
  quotesModalData: null,
  isLoadingQuotes: false,
  selectedQuote: null,
  orderShippingQuote: null,
  shippingOrderDetails: null,
  clearShippingContext: () => {},
  getOrderForCreateShipment: () => {},
  verifyShippingAddress: () => {},
  getCustomerShippingAddresses: () => {},
  setCcPath: () => {},
  clearCreateShipmentModalContext: () => {},
  clearQuotesModalContext: () => {},
  getShippingQuotes: () => {},
  getQuotesModalData: () => {},
  setQuoteStubs: () => {},
  setSelectedQuote: () => {},
  getQuoteForOrder: () => {},
  updateOrderShippingQuote: () => {},
  getShippingOrderDetails: () => {},
}

export const ShippingContext = createContext(initialState)

export const useShippingContext = () => useContext(ShippingContext)

export interface IShippingProviderProps {
  children?: React.ReactNode
}

export const ShippingProvider = (
  props: IShippingProviderProps,
): JSX.Element => {
  const { children } = props

  const [state, dispatch] = useReducer(shippingReducer, initialState)

  const clearShippingContext = () => {
    clearCreateShipmentModalContext()
    clearShippingAddressesContext()
    clearQuotesModalContext()
  }

  const clearShippingAddressesContext = () => {
    dispatch({
      type: GET_CUSTOMER_SHIPPING_ADDRESSES,
      payload: [],
    })
  }

  const clearCreateShipmentModalContext = () => {
    dispatch({
      type: CREATE_SHIPMENT_ORDER,
      payload: null,
    })
  }

  const clearQuotesModalContext = () => {
    dispatch({
      type: GET_QUOTES_FORM_DATA,
      payload: null,
    })
    dispatch({
      type: GET_SHIPPING_QUOTES,
      payload: [],
    })
    dispatch({
      type: SET_QUOTES_LOADING,
      payload: false,
    })
    dispatch({
      type: SET_SELECTED_QUOTE,
      payload: null,
    })
  }

  const setSelectedQuote = (quote: IShippingQuote) => {
    dispatch({
      type: SET_SELECTED_QUOTE,
      payload: quote,
    })
  }

  const getShippingOrderDetails = async (orders_id: string) => {
    const response = await api.get(`${API_SHIPPING_URL}order/${orders_id}`)
    toast.success('Order loaded')
    dispatch({
      type: SHIPPING_ORDER_DETAILS,
      payload: response.data,
    })
  }

  const getQuoteForOrder = async (orders_id: string) => {
    const response = await api.get(
      `${API_SHIPPING_URL}${orders_id}/current-quote`,
    )
    dispatch({
      type: ORDER_SHIPPING_QUOTE,
      payload: response.data,
    })
  }

  const updateOrderShippingQuote = async (
    quote: IShippingQuote,
    orders_id: string,
  ) => {
    const response = await api.post(`${API_SHIPPING_URL}quotes/update`, {
      quote,
      orders_id,
    })

    if (response.data.success) {
      dispatch({
        type: ORDER_SHIPPING_QUOTE,
        payload: response.data.data,
      })
      toast.success(response.data.message || 'Quote updated successfully')
    } else {
      toast.error(response.data.message || 'Error updating quote')
    }
  }

  const getQuotesModalData = async (orders_id: string) => {
    const response = await api.get(
      `${API_SHIPPING_URL}${orders_id}/quotes-details`,
    )

    if (response.data.success) {
      dispatch({
        type: GET_QUOTES_FORM_DATA,
        payload: response.data.data,
      })
    } else {
      toast.error(response.data.message || 'Error getting form data')
    }
  }

  const setQuoteStubs = (data: IShippingQuote[]) => {
    dispatch({
      type: GET_SHIPPING_QUOTES,
      payload: data,
    })
  }

  const setIsLoadingQuotes = (isLoading: boolean) => {
    dispatch({
      type: SET_QUOTES_LOADING,
      payload: isLoading,
    })
  }

  const getShippingQuotes = async (data: IQuotesModalData) => {
    const { shippingQuotes } = state

    if (!shippingQuotes) return

    setIsLoadingQuotes(true)

    const requests = shippingQuotes?.map((quote: IShippingQuote) =>
      api
        .post(`${API_SHIPPING_URL}quotes/get-quotes`, {
          ...data,
          carrier_option_id: quote.carrier_option_id,
          carrier_label: quote.carrier_name,
          stub_id: quote.id,
        })
        .then((response) => {
          if (response.data.success) {
            dispatch({
              type: UPDATE_QUOTE,
              payload: response.data.quote,
            })
          } else {
            toast.error(response.data.message || 'Error getting quotes')
          }
        })
        .catch((e) => {
          dispatch({
            type: UPDATE_QUOTE,
            payload: {
              ...quote,
              message: 'Unknown Error',
            },
          })
        }),
    )

    await Promise.allSettled(requests)

    setIsLoadingQuotes(false)
  }

  const verifyShippingAddress = async (shippingAddress: IShipAddress) => {
    const response = await api.post(
      `${API_SHIPPING_URL}verify-address`,
      shippingAddress,
    )

    if (response.data.success) {
      toast.success(response.data.message || 'Address verified successfully')
    } else {
      toast.error(response.data.errors.join('\n') || 'Error verifying address')
    }
  }

  const getOrderForCreateShipment = async (ordersId: string) => {
    const response = await api.get(
      `${API_SHIPPING_URL}${ordersId}/create-details`,
    )

    if (response.data.success) {
      dispatch({
        type: CREATE_SHIPMENT_ORDER,
        payload: response.data.data,
      })
    } else {
      toast.error(response.data.message || 'Error getting order details')
    }
  }

  const getCustomerShippingAddresses = async (customers_id: number) => {
    const headersValue: AxiosRequestConfig = { headers: authHeader() }
    const response = await axios.get(
      `${API_SHIPPING_URL}shipping-addresses/${customers_id}`,
      headersValue,
    )

    dispatch({
      type: GET_CUSTOMER_SHIPPING_ADDRESSES,
      payload: response.data,
    })
  }

  const setCcPath = (path: string) => {
    dispatch({
      type: SET_CC_PATH,
      payload: path,
    })
  }

  const value = {
    ...state,
    setCcPath,
    clearShippingContext,
    getOrderForCreateShipment,
    verifyShippingAddress,
    getCustomerShippingAddresses,
    clearCreateShipmentModalContext,
    clearQuotesModalContext,
    getShippingQuotes,
    getQuotesModalData,
    setQuoteStubs,
    setSelectedQuote,
    getQuoteForOrder,
    updateOrderShippingQuote,
    getShippingOrderDetails,
  }

  return (
    <ShippingContext.Provider value={value}>
      {children}
    </ShippingContext.Provider>
  )
}
