import React, { useEffect, useRef, useState } from 'react'
import { Card, Form, Container, Row, Col, Button, Alert } from 'react-bootstrap'
import RwFormikInput from '../../molecules/RwFormikInput'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { determineChanges, automaskEmail } from '../../../helpers'
import { automaskMobilePhoneNumber } from '../../../helpers'
import RwCard from '../../molecules/RwCard'
import { useNavigate, useParams } from 'react-router-dom'
import RwFormikCheckbox from '../../molecules/RwFormikCheckbox'
import RwSubmitButton from '../../molecules/RwButton/RwSubmitButton'
import RwCloseButton from '../../molecules/RwButton/RwCloseButton'
import { useStaffContext } from '../../../context/staff/StaffProvider'
import { YUP_EMAIL } from '../../../helpers/validation'
import RwButtonGroup from '../../molecules/RwButton/RwButtonGroup'
import RwNavBar from '../../molecules/RwNav/RwNavBar'
import RwNavItem from '../../molecules/RwNav/RwNavItem'
import CdnImg from '../../organisms/Common/CdnImg'
import AvatarModal from '../../organisms/Common/Modal/AvatarModal'
import { SketchPicker } from 'react-color'
import RwFormikHiddenInput from '../../molecules/RwFormikHiddenInput'
import ProtectedForm from '../../molecules/ProtectedForm'
import { SETTINGS_STAFF_UPDATE_PERM } from '../../../constants/iam'
import PasswordModal from '../../organisms/Common/Modal/PasswordModal'
import { IChangePasswordData } from '../../../context/user/types'
import AuditLogModal from '../../organisms/Common/Modal/AuditLogModal'
import WarehouseRoles from '../../organisms/Product/WarehouseRoles'
import ErrorComponent from '../../../components/pages/products/products-editor/errors'
import RwNavHeader from '../../molecules/RwNav/RwNavHeader'
import { PinKey } from '../../molecules/RwNav/types'
import RwIcon from '../../molecules/RwIcon'

export const StaffAccountBreadcrumb: React.FC = () => {
  const { staffAccount } = useStaffContext()

  return <span>{staffAccount?.name ? staffAccount?.name : 'New Account'}</span>
}

const StaffAccount: React.FC = () => {
  const [showEditAvatar, setShowEditAvatar] = useState(false)
  const [showPasswordModal, setShowPasswordModal] = useState(false)
  const [tagColor, setTagColor] = useState<any>({
    background: '#fff',
  })
  const [tagFontColor, setTagFontColor] = useState<any>({
    background: '#000',
  })
  const { staff_id } = useParams()
  const formRef = useRef<any>()
  const navigate = useNavigate()
  const {
    staffAccount,
    getStaffAccount,
    updateStaffAccount,
    clearStaffAccountContext,
    warehouseRoles,
    getWarehouseRoles,
    uploadAvatar,
    changePassword,
  } = useStaffContext()

  useEffect(() => {
    getWarehouseRoles()
  }, [])

  useEffect(() => {
    if (staff_id && staff_id != 'new') {
      getStaffAccount(staff_id)
    }

    return () => {
      clearStaffAccountContext()
    }
  }, [staff_id])

  const formErrors =
    formRef?.current?.errors !== undefined ? formRef?.current?.errors : null

  useEffect(() => {
    if (staffAccount) {
      if (formRef.current && staffAccount) {
        formRef.current.validateForm()

        if (formErrors !== undefined && formErrors) {
          Object.keys(formErrors).forEach((formError: any) => {
            formRef.current.setFieldTouched(formError)
          })
        }
      }
    }
  }, [staffAccount, formRef.current, formErrors])

  useEffect(() => {
    if (staffAccount) {
      setTagColor({
        hex: staffAccount.tag_color,
      })
      setTagFontColor({
        hex: staffAccount.tag_font_color,
      })
    }
  }, [staffAccount])

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    email: YUP_EMAIL.required('Required'),
    username: Yup.string().required('Required'),
    pincode: Yup.string().min(6).max(6),
    is_active: Yup.boolean().required(),
    warehouse_roles: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number().required('Required'),
          warehouse_role_id: Yup.number().required('Required'),
          is_primary: Yup.boolean(),
        }),
      )
      .test(
        'one-primary',
        'Exactly one primary role must be selected',
        function (roles) {
          const { is_active } = this.parent

          // If is_active is false, primary role requirement is not enforced
          if (!is_active) return true

          // Ensure roles is not undefined or null
          if (!roles || roles.length === 0) return false

          const primaryRoles = roles.filter((role) => role.is_primary === true)
          return primaryRoles.length === 1 // Ensure exactly one is selected
        },
      ),
  })

  const initialValues = {
    active: staffAccount?.active || false,
    name: staffAccount?.name || '',
    email: staffAccount?.email || '',
    username: staffAccount?.username || '',
    home_phone: staffAccount?.home_phone || '',
    mobile_phone: staffAccount?.mobile_phone || '',
    work_phone: staffAccount?.work_phone || '',
    pincode: staffAccount?.pincode || '',
    is_active: staffAccount?.is_active || false,
    can_edit_locations: staffAccount?.can_edit_locations || false,
    is_delivery_driver: staffAccount?.is_delivery_driver || false,
    view_orders: staffAccount?.view_orders || false,
    view_fulfillment: staffAccount?.view_fulfillment || false,
    view_customers: staffAccount?.view_customers || false,
    view_products: staffAccount?.view_products || false,
    view_inventory: staffAccount?.view_inventory || false,
    view_accounting: staffAccount?.view_accounting || false,
    view_settings: staffAccount?.view_settings || false,
    view_commission_report: staffAccount?.view_commission_report || false,
    inventory_taker: staffAccount?.inventory_taker || false,
    view_airtable_config: staffAccount?.view_airtable_config || false,
    show_prod_banner: staffAccount?.show_prod_banner || false,
    can_update_inventory: staffAccount?.can_update_inventory || false,
    is_unassignable: staffAccount?.is_unassignable || false,
    view_credit_memo_requests: staffAccount?.view_credit_memo_requests || false,
    is_auto_pick: staffAccount?.is_auto_pick || false,
    is_relocate: staffAccount?.is_relocate || false,
    is_sales_rep: staffAccount?.is_sales_rep || false,
    can_edit_pricing: staffAccount?.can_edit_pricing || false,
    assign_leads: staffAccount?.assign_leads || false,
    can_have_task: staffAccount?.can_have_task || false,
    is_sales_manager: staffAccount?.is_sales_manager || false,
    is_sales_support: staffAccount?.is_sales_support || false,
    photo_url: staffAccount?.photo_url || '',
    tag_color: staffAccount?.tag_color || '',
    tag_font_color: staffAccount?.tag_font_color || '',
    can_update_capacity: staffAccount?.can_update_capacity || false,
    warehouse_roles: staffAccount?.warehouse_roles || [], // placeholder
    warehouse_staff_id: staffAccount?.warehouse_staff_id || '',
    is_auto_logout: staffAccount?.is_auto_logout || false,

  }

  const onSubmit = async (values: any, submitProps: FormikHelpers<any>) => {
    const updatedStaffInfo = staffAccount
      ? determineChanges(staffAccount, values, ['staff_id'])
      : { ...values, staff_id: null }

    // Use await to wait for the promise to resolve
    let new_staff_id: number | void = await updateStaffAccount(updatedStaffInfo)
    let password_set = true
    if (
      new_staff_id !== null &&
      new_staff_id !== undefined &&
      new_staff_id < 0
    ) {
      password_set = false
      new_staff_id = new_staff_id * -1
    }

    if (!password_set) {
      setShowAlert(true)
    }

    if (
      new_staff_id !== null &&
      new_staff_id !== undefined &&
      new_staff_id !== Number(staff_id)
    ) {
      // Reload page with new staff_id
      navigate(`/settings/staff-accounts/${new_staff_id}`, { replace: true })
    }
  }

  const handleClose = () => {
    navigate(-1)
  }

  const handleAvatarClick = () => {
    setShowEditAvatar(true)
  }
  const handleAvatarClose = () => {
    setShowEditAvatar(false)
  }

  const handleAvatarSave = (image_data: string) => {
    if (staff_id) {
      uploadAvatar({
        staff_id: staff_id,
        image_data: image_data,
      })
      setShowEditAvatar(false)
    }
  }

  const handleTagColorChange = (color: any) => {
    setTagColor(color)
  }

  const handleTagFontColorChange = (color: any) => {
    setTagFontColor(color)
  }

  const handlePasswordSave = (values: IChangePasswordData) => {
    changePassword({
      ...values,
      staff_id: staff_id,
    })
    setShowPasswordModal(false)
  }

  const [showAuditModal, setShowAuditModal] = useState<boolean>(false)

  const fieldMap = {
    staff_id: 'Staff',
    wms_warehouse_staff_id: 'WMS Warehouse Staff',
    spark_user_id: 'Spark User',
    home_phone: 'Home Phone',
    mobile_phone: 'Mobile Phone',
    work_phone: 'Work Phone',
    is_sales_manager: 'Sales Manager',
    is_sales_rep: 'Sales Rep',
    is_sales_support: 'Sales Support',
    assign_leads: 'Assign Leads',
    can_have_task: 'Tasks and Calls',
    can_edit_pricing: 'Edit Pricing',
    view_commission_report: 'Commission Reports',
    view_credit_memo_requests: 'Credit Memo Requests',
    view_orders: 'Orders',
    view_customers: 'Customers',
    view_products: 'Products',
    view_fulfillment: 'Fullfillment',
    view_inventory: 'Inventory',
    view_accounting: 'Accounting',
    view_settings: 'Settings',
    can_update_inventory: 'Update Inventory',
    can_edit_locations: 'Edit Locations',
    is_delivery_driver: 'Delivery Driver',
    inventory_taker: 'Inventory Taker Tool',
    is_unassignable: 'No Assignments',
    can_update_capacity: 'Capacity Calendar',
    tag_color: 'Tag Background Color',
    tag_font_color: 'Tag Font Color',
    photo_url: 'Avatar',
    is_active: 'WMS Active',
    is_auto_pick: 'Auto Pick Only',
    is_relocate: 'Relocate Access',
    is_auto_logout: 'Auto Logout Enabled',
    wms_warehouse_staff_to_warehouse_roles_id: 'Warehouse Role',
  }

  const [showAlert, setShowAlert] = useState(false)

  return (
    <div>
      <Formik
        innerRef={formRef}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          isValid,
          values,
          dirty,
          errors,
          setFieldValue,
          resetForm,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <ProtectedForm permission={SETTINGS_STAFF_UPDATE_PERM}>
              <RwNavHeader
                pinKey={PinKey.STAFF_ACCOUNT}
                title={staffAccount?.name}
                handleClose={handleClose}
              >
                <RwNavBar>
                  {staff_id != 'new' ? (
                    <>
                      <RwNavItem
                        variant="key"
                        title="Set/Change Password"
                        onClick={() => {
                          setShowPasswordModal(true)
                        }}
                      />
                      <RwNavItem
                        variant="audit"
                        onClick={() => {
                          setShowAuditModal(true)
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )}

                  <RwNavItem
                    fill
                    variant="revert"
                    disabled={!dirty}
                    onClick={resetForm}
                  />

                  {isValid ? (
                    <RwNavItem
                      variant="save"
                      disabled={!dirty || !isValid}
                      onClick={() => {
                        if (dirty && isValid) {
                          handleSubmit()
                        }
                      }}
                    />
                  ) : (
                    <RwNavItem variant="form-errors" disabled={true} />
                  )}
                </RwNavBar>
              </RwNavHeader>
              <div className="p-4">
                {!isValid && (
                  <Alert variant="danger" className="fw-bold fs-5">
                    You must fix errors before you can save.
                  </Alert>
                )}
                {showAlert && (
                  <Alert
                    className="mt-2"
                    variant="info"
                    onClose={() => setShowAlert(false)}
                    dismissible
                  >
                    <p>
                      <RwIcon className="fs-3" icon="Info" /> Please set the
                      password for the new user.
                    </p>
                  </Alert>
                )}
                <Container className="m-0 p-0" fluid>
                  <Row>
                    <Col lg={12} xl={6} xxl={4} id="profile">
                      <RwCard className="">
                        <Card.Header className="fw-bold">Profile</Card.Header>
                        <Card.Body className="p-2">
                          <RwFormikCheckbox
                            type="switch"
                            name="active"
                            label="Active User"
                            className="mb-2"
                          />
                          <RwFormikInput name="name" label="Name" />
                          <RwFormikInput
                            name="email"
                            label="Email"
                            type="email"
                            onKeyPress={(
                              e: React.KeyboardEvent<HTMLInputElement>,
                            ) => {
                              if (e.key === ' ') {
                                e.preventDefault()
                              }
                            }}
                            onChange={async (
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              handleChange(e)
                              const email = await automaskEmail(e.target.value)
                              setFieldValue('email', email)
                            }}
                          />
                          <RwFormikInput name="username" label="User name" />
                          <RwFormikInput
                            type="tel"
                            name="home_phone"
                            label="Home Phone"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              handleChange(e)
                              const phoneNumber = automaskMobilePhoneNumber(
                                e.target.value,
                              )
                              setFieldValue('home_phone', phoneNumber)
                            }}
                          />
                          <RwFormikInput
                            type="tel"
                            name="mobile_phone"
                            label="Mobile Phone"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              handleChange(e)
                              const phoneNumber = automaskMobilePhoneNumber(
                                e.target.value,
                              )
                              setFieldValue('mobile_phone', phoneNumber)
                            }}
                          />
                          <RwFormikInput
                            type="tel"
                            name="work_phone"
                            label="Work Phone"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              handleChange(e)
                              const phoneNumber = automaskMobilePhoneNumber(
                                e.target.value,
                              )
                              setFieldValue('work_phone', phoneNumber)
                            }}
                          />
                        </Card.Body>
                      </RwCard>
                    </Col>
                    <Col lg={12} xl={6} xxl={4} id="avatar">
                      <RwCard>
                        <Card.Header className="fw-bold">Avatar</Card.Header>
                        <Card.Body className="p-2">
                          <Button
                            as="div"
                            className="mb-2 cursor-pointer text-center"
                            onClick={handleAvatarClick}
                          >
                            <CdnImg
                              path={staffAccount?.photo_url}
                              height="400px"
                              alt="Edit Avatar"
                              title="Edit Avatar"
                              className="rounded-4"
                            />
                          </Button>
                        </Card.Body>
                      </RwCard>
                    </Col>
                    <Col lg={12} xl={6} xxl={4} id="spark">
                      <RwCard>
                        <Card.Header className="fw-bold">
                          Spark Accessibility
                        </Card.Header>
                        <Card.Body className="p-2">
                          <Container fluid>
                            <Row>
                              <Col sm={12} md={6}>
                                <RwFormikCheckbox
                                  type="switch"
                                  name="view_orders"
                                  label="Orders"
                                />
                                <RwFormikCheckbox
                                  type="switch"
                                  name="view_fulfillment"
                                  label="Fulfillment"
                                />
                                <RwFormikCheckbox
                                  type="switch"
                                  name="view_customers"
                                  label="Customers"
                                />
                                <RwFormikCheckbox
                                  type="switch"
                                  name="view_products"
                                  label="Products"
                                />
                                <RwFormikCheckbox
                                  type="switch"
                                  name="view_inventory"
                                  label="Inventory"
                                />
                              </Col>
                              <Col sm={12} md={6}>
                                <RwFormikCheckbox
                                  type="switch"
                                  name="view_accounting"
                                  label="Accounting"
                                />
                                <RwFormikCheckbox
                                  type="switch"
                                  name="view_settings"
                                  label="Settings"
                                />
                                <RwFormikCheckbox
                                  type="switch"
                                  name="view_commission_report"
                                  label="Commission Reports"
                                />
                                <RwFormikCheckbox
                                  type="switch"
                                  name="view_credit_memo_requests"
                                  label="Credit Memo Requests"
                                />                                
                                <RwFormikCheckbox
                                type="switch"
                                name="is_auto_logout"
                                label="Auto Logout Enabled"
                              />
                              </Col>
                            </Row>
                          </Container>
                        </Card.Body>
                      </RwCard>
                    </Col>
                    <Col lg={12} xl={6} xxl={4} id="sales">
                      <RwCard>
                        <Card.Header className="fw-bold">Sales</Card.Header>
                        <Card.Body className="p-2">
                          <Container fluid>
                            <Row>
                              <Col sm={12} md={6}>
                                <RwFormikCheckbox
                                  type="switch"
                                  name="is_sales_manager"
                                  label="Sales Manager"
                                />
                                <RwFormikCheckbox
                                  type="switch"
                                  name="is_sales_rep"
                                  label="Sales Rep"
                                />
                                <RwFormikCheckbox
                                  type="switch"
                                  name="is_sales_support"
                                  label="Sales Support"
                                />
                              </Col>
                              <Col sm={12} md={6}>
                                <RwFormikCheckbox
                                  type="switch"
                                  name="can_edit_pricing"
                                  label="Edit Pricing"
                                />
                                <RwFormikCheckbox
                                  type="switch"
                                  name="assign_leads"
                                  label="Assign Leads"
                                />
                                <RwFormikCheckbox
                                  type="switch"
                                  name="can_have_task"
                                  label="Tasks and Calls"
                                />
                              </Col>
                            </Row>
                          </Container>
                        </Card.Body>
                      </RwCard>
                    </Col>
                    <Col lg={12} xl={6} xxl={4} id="warehouse">
                      <RwCard>
                        <Card.Header className="fw-bold">Warehouse</Card.Header>
                        <Card.Body className="p-2">
                          <RwFormikCheckbox
                            type="switch"
                            name="is_active"
                            label="WMS Active"
                          />
                          <RwFormikInput name="pincode" label="WMS PIN" />
                          <Button
                            className="mb-2"
                            onClick={() => {
                              const timestamp = new Date().getTime().toString()
                              setFieldValue('pincode', timestamp.slice(-6))
                            }}
                          >
                            Create PIN
                          </Button>
                          {!isValid && <ErrorComponent errors={errors} />}
                          <WarehouseRoles
                            warehouseRoles={values?.warehouse_roles}
                            warehouseRoleOptions={warehouseRoles}
                            warehouseStaffId={values?.warehouse_staff_id}
                          />
                          <Container fluid>
                            <Row>
                              <Col sm={12} md={6}>
                                <RwFormikCheckbox
                                  type="switch"
                                  name="can_update_inventory"
                                  label="Update Inventory"
                                />
                                <RwFormikCheckbox
                                  type="switch"
                                  name="can_edit_locations"
                                  label="Edit Locations"
                                />
                                <RwFormikCheckbox
                                  type="switch"
                                  name="is_auto_pick"
                                  label="Auto Pick Only"
                                />
                                <RwFormikCheckbox
                                  type="switch"
                                  name="is_unassignable"
                                  label="No Assignments"
                                />
                              </Col>
                              <Col sm={12} md={6}>
                                <RwFormikCheckbox
                                  type="switch"
                                  name="is_relocate"
                                  label="Relocate Access"
                                />
                                <RwFormikCheckbox
                                  type="switch"
                                  name="is_delivery_driver"
                                  label="Delivery Driver"
                                />
                                <RwFormikCheckbox
                                  type="switch"
                                  name="inventory_taker"
                                  label="Inventory Taker Tool"
                                />
                                <RwFormikCheckbox
                                  type="switch"
                                  name="can_update_capacity"
                                  label="Capacity Calendar"
                                />
                              </Col>
                            </Row>
                          </Container>
                        </Card.Body>
                      </RwCard>
                    </Col>
                    <Col lg={12} xl={6} xxl={4} id="tags">
                      <RwCard>
                        <Card.Header className="fw-bold">Tags</Card.Header>
                        <Card.Body className="p-2">
                          <div className="mb-4 mt-2">
                            <span
                              className="rounded-pill p-2"
                              style={{
                                backgroundColor: tagColor.hex,
                                color: tagFontColor.hex,
                                height: '20px',
                              }}
                            >
                              {staffAccount?.name
                                ? staffAccount?.name
                                : 'Firstname Lastname'}
                            </span>
                          </div>
                          <div className="mb-2">
                            <RwFormikHiddenInput name="tag_color" />
                            <RwFormikHiddenInput name="tag_font_color" />
                            <Container fluid>
                              <Row>
                                <Col>
                                  <div>
                                    <div>Background Color</div>
                                    <SketchPicker
                                      disableAlpha
                                      color={tagColor}
                                      onChange={(colorResult) => {
                                        handleTagColorChange(colorResult)
                                        setFieldValue(
                                          'tag_color',
                                          colorResult.hex,
                                        )
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col>
                                  <div>
                                    <div>Font Color</div>
                                    <SketchPicker
                                      disableAlpha
                                      color={tagFontColor}
                                      onChange={(colorResult) => {
                                        handleTagFontColorChange(colorResult)
                                        setFieldValue(
                                          'tag_font_color',
                                          colorResult.hex,
                                        )
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </div>
                        </Card.Body>
                      </RwCard>
                    </Col>
                  </Row>
                </Container>

                <RwButtonGroup>
                  {isValid ? (
                    <RwSubmitButton disabled={!dirty || !isValid}>
                      Save
                    </RwSubmitButton>
                  ) : (
                    <RwNavItem variant="form-errors" disabled={true} />
                  )}

                  <RwCloseButton onClick={handleClose} />
                </RwButtonGroup>
              </div>
            </ProtectedForm>
          </Form>
        )}
      </Formik>
      <AvatarModal
        show={showEditAvatar}
        handleClose={handleAvatarClose}
        handleSave={handleAvatarSave}
      />
      <PasswordModal
        show={showPasswordModal}
        handleClose={() => {
          setShowPasswordModal(false)
        }}
        handleSave={handlePasswordSave}
      />
      <AuditLogModal
        showModal={showAuditModal}
        handleClose={() => {
          setShowAuditModal(false)
        }}
        table={'staff_login'}
        keyValue={staff_id || ''}
        title={`${staffAccount?.name}  Audit Log`}
        fieldMap={fieldMap}
        hideFields={[]}
      />
    </div>
  )
}

export default StaffAccount
