import { createContext, useReducer, useContext } from 'react'
import {
  IFilterState,
  IOrdersListFilters,
  IShippingOrdersListFilters,
} from './types'
import {
  defaultOrdersListFilters,
  SET_ORDERS_LIST_FILTERS,
  CLEAR_FILTER_CONTEXT,
  CLEAR_ORDERS_LIST_FILTERS,
  CLEAR_SHIPPING_ORDERS_LIST_FILTERS,
  defaultShippingOrdersListFilters,
  SET_SHIPPING_ORDERS_LIST_FILTERS,
} from './constants'
import filterReducer from './FilterReducer'
import { IContextProviderProps } from '../../types/interfaces'

export const initialState: IFilterState = {
  ordersListFilters: defaultOrdersListFilters,
  shippingOrdersListFilters: defaultShippingOrdersListFilters,
  setOrdersListFilters: () => {},
  setShippingOrdersListFilters: () => {},
  clearFilterContext: () => {},
  clearOrdersListFilters: () => {},
  clearShippingOrdersListFilters: () => {},
}

export const FilterContext = createContext<IFilterState>(initialState)

export const useFilterContext = () => useContext(FilterContext)

export const FilterProvider = (props: IContextProviderProps): JSX.Element => {
  const { children } = props
  const [state, dispatch] = useReducer(filterReducer, initialState)

  // Cleanups here. Don't use a payload, and have the reducer return the default from ./constants.js
  const clearFilterContext = () => {
    dispatch({ type: CLEAR_FILTER_CONTEXT })
  }

  const clearOrdersListFilters = () => {
    dispatch({
      type: CLEAR_ORDERS_LIST_FILTERS,
    })
  }

  const clearShippingOrdersListFilters = () => {
    dispatch({
      type: CLEAR_SHIPPING_ORDERS_LIST_FILTERS,
    })
  }

  // Setters
  const setOrdersListFilters = (payload: IOrdersListFilters) => {
    dispatch({ type: SET_ORDERS_LIST_FILTERS, payload })
  }

  const setShippingOrdersListFilters = (
    payload: IShippingOrdersListFilters,
  ) => {
    dispatch({ type: SET_SHIPPING_ORDERS_LIST_FILTERS, payload })
  }

  const value = {
    ...state,
    clearFilterContext,
    clearOrdersListFilters,
    clearShippingOrdersListFilters,
    setOrdersListFilters,
    setShippingOrdersListFilters,
  }

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  )
}
