export const GET_NOTES = 'GET_NOTES'
export const SET_NOTE_TYPE_ID = 'SET_NOTE_TYPE_ID'
export const SET_NOTE_KEY_VALUE = 'SET_NOTE_KEY_VALUE'
export const CLEANUP_NOTES = 'CLEANUP_NOTES'
export const CLEANUP_NOTE_TYPE_ID = 'CLEANUP_NOTE_TYPE_ID'
export const CLEANUP_NOTE_KEY_VALUE = 'CLEANUP_NOTE_KEY_VALUE'
export const SET_GET_NOTE_TYPES = 'SET_GET_NOTE_TYPES'
export const CLEANUP_GET_NOTE_TYPES = 'CLEANUP_GET_NOTE_TYPES'

// ORDERS
export const NOTE_TYPE_ORDER_SHIPPING_ID = 1
export const NOTE_TYPE_ORDER_WARESHOUSE_ID = 2
export const NOTE_TYPE_ORDER_SALES_ID = 3
export const NOTE_TYPE_ORDER_GENERIC_ID = 4
export const NOTE_TYPE_ORDER_SPLIT_ORDER_ID = 23
export const NOTE_TYPE_ORDER_PAYMENT_ID = 24

// PRICING
export const NOTE_TYPE_PRICING_PRODUCT_MULTIPLIER_VERSION_ID = 5
export const NOTE_TYPE_PRICING_PRODUCT_MULTIPLIER_DETAIL_ID = 6
export const NOTE_TYPE_PRICING_SUP_MAN_VERSION_ID = 7
export const NOTE_TYPE_PRICING_SUP_MAN_DETAIL_ID = 8
export const NOTE_TYPE_PRICING_MANUFACTURERS_VERSION_ID = 9
export const NOTE_TYPE_PRICING_MANUFACTURERS_DETAIL_ID = 10
export const NOTE_TYPE_PRICING_CATEGORY_MULTIPLIER_VERSION_ID = 11
export const NOTE_TYPE_PRICING_CATEGORY_MULTIPLIER_DETAIL_ID = 12
export const NOTE_TYPE_PRICING_FOREX_VERSION_ID = 13
export const NOTE_TYPE_PRICING_FOREX_DETAIL_ID = 14
export const NOTE_TYPE_PRICING_INBOUND_TEMPLATE_VERSION_ID = 15
export const NOTE_TYPE_PRICING_INBOUND_TEMPLATE_DETAIL_ID = 16
export const NOTE_TYPE_PRICING_INBOUND_FREIGHT_VERSION_ID = 17
export const NOTE_TYPE_PRICING_INBOUND_FREIGHT_DETAIL_ID = 18
export const NOTE_TYPE_PRICING_INBOUND_TEMPLATE_ITEMS_VERSION_ID = 19
export const NOTE_TYPE_PRICING_INBOUND_TEMPLATE_ITEMS_DETAIL_ID = 20
export const NOTE_TYPE_PRICING_SUPPLIERS_VERSION_ID = 21
export const NOTE_TYPE_PRICING_SUPPLIERS_DETAIL_ID = 22
