import {
  SET_NOTE_KEY_VALUE,
  SET_NOTE_TYPE_ID,
  GET_NOTES,
  CLEANUP_NOTES,
  CLEANUP_NOTE_TYPE_ID,
  CLEANUP_NOTE_KEY_VALUE,
  SET_GET_NOTE_TYPES,
  CLEANUP_GET_NOTE_TYPES,
} from './constants'
import { INotesAction, INotesState } from './types'
import { initialState } from './NotesProvider'

export default function notesReducer(
  state: INotesState,
  action: INotesAction,
): INotesState {
  const payload = action.payload
  switch (action.type) {
    case SET_NOTE_TYPE_ID:
      return { ...state, noteTypeID: payload }
    case SET_NOTE_KEY_VALUE:
      return { ...state, keyValue: payload }
    case SET_GET_NOTE_TYPES:
      return { ...state, getNoteTypes: payload }
    case GET_NOTES:
      return { ...state, notes: payload }
    case CLEANUP_NOTES:
      return { ...state, notes: initialState.notes }
    case CLEANUP_NOTE_TYPE_ID:
      return { ...state, noteTypeID: initialState.noteTypeID }
    case CLEANUP_NOTE_KEY_VALUE:
      return { ...state, keyValue: initialState.keyValue }
    case CLEANUP_GET_NOTE_TYPES:
      return { ...state, getNoteTypes: initialState.getNoteTypes }
    default:
      return state
  }
}
